export const getLocaleTitle = (locale = "es", colorName) => {
  const title =
    locale === "es"
      ? `Códigos html del Color ${colorName} ✚ Esquemas ⇨【RGB,HSL,HEX】`
      : locale === "en"
      ? `HTML codes for the color ${colorName} ✚ Schemes ⇨【RGB,HSL,HEX】`
      : locale === "pt"
      ? `Códigos HTML da cor ${colorName} ✚ Esquemas ⇨【RGB,HSL,HEX】`
      : locale === "fr"
      ? `Les codes HTML de la couleur ${colorName} ✚ Schemas ⇨【RGB, HSL, HEX】`
      : "";

  return title;
};
