export const getLocaleDescription = (locale = "es", colorName) => {
  const description =
    locale === "es"
      ? `Descubre los códigos del color ${colorName}, desde hexadecimal, rgb, hsl, hsv ➕ cmyk,  además de sus esquemas, sombras y tintes e ideas.`
      : locale === "en"
      ? `Discover the color codes for ${colorName}, including hexadecimal, RGB, HSL, HSV ➕ CMYK, as well as its schemes, shades, tints, and ideas.`
      : locale === "pt"
      ? `Descubra os códigos de cor para ${colorName}, incluindo hexadecimal, RGB, HSL, HSV ➕ CMYK, além de seus esquemas, sombras, tons e ideias.`
      : locale === "fr"
      ? `Découvre les codes de couleur ${colorName}, du hexadécimal, rgb, hsl, hsv ➕ cmyk, ainsi que leurs schémas, ombres, teintes et idées.`
      : "";

  return description;
};
