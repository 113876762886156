import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../../src/components/layout";
import {
  color_page_wrapper,
  color_page_main,
  second_section_color_page,
  second_section_color_page_main,
  color_page_breadcrumb,
  color_page_breadcrumb_wrapper,
  section_color_page_white,
} from "../../src/styles/modules/color_page.module.css";
import CodeSnippetColor from "./components/codeSnippetColor";
import ColorCompositionSection from "./components/colorCompositionSection";
import ColorsBox from "./components/colorsBox";
import ColorContrastCheckerInPost from "./components/contrastChecker/ContrastCheckerInPost";
import IntroColor from "./components/introColor.js";
import MonocromathicSection from "./components/monocromathicSection";
import SectionColorSchemesAndShades from "./components/sectionColorSchemesAndShades";
import BreadCrumbs from "../../src/components/BreadCrumps";
import { transformSlugToArrBreadcrumbs } from "../../src/helpers/transformSlugToArr";
import WebPageJsonLD from "../../src/components/SEO/WebPageJsonLD";
import { ContextLocale } from "../../src/context/ContextLocale";
// import AdWithPlaceholder from "../../src/components/Ads/AdWithPlaceholder";
import { BasicHeadTags } from "../../src/components/SEO/BasicHeadTags";
import { getLocaleTitle } from "./helpers/getLocaleTitle";
import { getLocaleDescription } from "./helpers/getLocaleDescription";
import { url as mainUrl } from "../../config/website";
import { getCannonicalURL } from "./helpers/getCannonicalURL";
// import AdManaWithPlaceholder from "../../src/components/Ads/AdManaWithPlaceHolder";

function ColorPage({ data, pageContext }) {
  const { setLang } = useContext(ContextLocale);
  const {
    colorHex,
    colorName,
    locale,
    secondName,

    boxHtmlColorLinks,
    url,
  } = pageContext;
  setLang(locale);
  const breadCrumbsList = transformSlugToArrBreadcrumbs(url, locale);

  return (
    <Layout locale={locale}>
      <div
        className={color_page_breadcrumb}
        style={{ backgroundColor: colorHex }}
      >
        <div className={color_page_breadcrumb_wrapper}>
          <BreadCrumbs breadCrumbsList={breadCrumbsList} />
          {/* <AdManaWithPlaceholder
            path="/22250597679/ptdc_banner_bottom"
            size={[
              [300, 250],
              [970, 250],
              [728, 250],
              [970, 90],
              [728, 90],
            ]}
            id="ptdc_banner_bottom"
          /> */}
          {/* <AdWithPlaceholder
            ins={`<!-- ptdc-color -->
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-3833126112632805"
             data-ad-slot="5789449190"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>`}
          /> */}
        </div>
      </div>
      <section
        className={color_page_wrapper}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <main className={color_page_main}>
          <IntroColor
            colorName={colorName}
            colorHex={colorHex}
            withImage={true}
            image={data.image.childImageSharp.gatsbyImageData}
            locale={locale}
          />
          <MonocromathicSection
            locale={locale}
            colorName={colorName}
            colorHex={colorHex}
          />
          <ColorCompositionSection
            locale={locale}
            colorName={colorName}
            colorHex={colorHex}
          />
        </main>
      </section>

      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={second_section_color_page_main}>
          <SectionColorSchemesAndShades
            colorName={colorName}
            colorHex={colorHex}
            locale={locale}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={section_color_page_white}>
          <ColorContrastCheckerInPost
            colorName={colorName}
            colorHex={colorHex}
            locale={locale}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={section_color_page_white}>
          <CodeSnippetColor
            colorName={secondName ? secondName : colorName}
            colorHex={colorHex}
            locale={locale}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={section_color_page_white}>
          <h2 className="heading_color_page">
            {locale === "es"
              ? `Aquí hay más colores con todos sus códigos`
              : locale === "en"
              ? `Here are more colors with all their codes`
              : locale === "pt"
              ? `Aqui estão mais cores com todos os seus códigos.`
              : locale === "fr"
              ? "Voici plus de couleurs avec tous leurs codes"
              : ""}
          </h2>
          <ColorsBox links={boxHtmlColorLinks} locale={locale} />
        </div>
      </section>
    </Layout>
  );
}

export const Head = ({ data, pageContext }) => {
  const { colorName, locale, url } = pageContext;

  const breadCrumbsList = transformSlugToArrBreadcrumbs(url, locale);
  const metaTitle = getLocaleTitle(locale, colorName);
  const metaDescription = getLocaleDescription(locale, colorName);
  const canonicalURL = getCannonicalURL(url);

  return (
    <>
      <BasicHeadTags
        title={metaTitle}
        description={metaDescription}
        type="article"
        image={`${mainUrl}${data.image.publicURL}`}
        canonicalUrl={canonicalURL}
        slug={url}
      />
      <WebPageJsonLD
        title={metaTitle}
        imagePublicURL={data.image.publicURL}
        imageWidth={data.image.childImageSharp.original.width}
        imageHeight={data.image.childImageSharp.original.height}
        slug={url}
        description={metaDescription}
        breadcrumbs={breadCrumbsList}
      />
    </>
  );
};

export const query = graphql`
  query ($imageName: String!) {
    image: file(name: { eq: $imageName }) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 340
          width: 340
          quality: 100
        )
        original {
          height
          width
        }
      }
    }
  }
`;

export default ColorPage;
